import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<section class=\"page-section\">\n  <header class=\"page-section__header\">\n    <h2 class=\"page-section__title\">{{t \"components.administration.organizations-import.title\"}}</h2>\n  </header>\n  <p>{{t \"components.administration.organizations-import.description\"}}</p>\n  <br />\n  <PixButtonUpload\n    @id=\"orga-file-upload\"\n    @onChange={{this.importOrganizations}}\n    @backgroundColor=\"transparent-light\"\n    @isBorderVisible={{true}}\n    @size=\"small\"\n    accept=\".csv\"\n  >\n    {{t \"components.administration.organizations-import.upload-button\"}}\n  </PixButtonUpload>\n</section>", {"contents":"<section class=\"page-section\">\n  <header class=\"page-section__header\">\n    <h2 class=\"page-section__title\">{{t \"components.administration.organizations-import.title\"}}</h2>\n  </header>\n  <p>{{t \"components.administration.organizations-import.description\"}}</p>\n  <br />\n  <PixButtonUpload\n    @id=\"orga-file-upload\"\n    @onChange={{this.importOrganizations}}\n    @backgroundColor=\"transparent-light\"\n    @isBorderVisible={{true}}\n    @size=\"small\"\n    accept=\".csv\"\n  >\n    {{t \"components.administration.organizations-import.upload-button\"}}\n  </PixButtonUpload>\n</section>","moduleName":"pix-admin/components/administration/organizations-import.hbs","parseOptions":{"srcName":"pix-admin/components/administration/organizations-import.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class OrganizationsImport extends Component {
  @service intl;
  @service notifications;
  @service router;
  @service store;

  @action
  async importOrganizations(files) {
    this.notifications.clearAll();

    const adapter = this.store.adapterFor('organizations-import');
    try {
      await adapter.addOrganizationsCsv(files);
      this.notifications.success(this.intl.t('components.administration.organizations-import.notifications.success'));
    } catch (errorResponse) {
      const errors = errorResponse.errors;

      if (!errors) {
        return this.notifications.error(this.intl.t('common.notifications.generic-error'));
      }
      errors.forEach((error) => {
        switch (error.code) {
          case 'MISSING_REQUIRED_FIELD_NAMES':
            this.notifications.error(`${error.meta}`, { autoClear: false });
            break;
          default:
            this.notifications.error(error.detail, { autoClear: false });
        }
      });
    } finally {
      this.isLoading = false;
    }
  }
}
