import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PixModal @title=\"Paramètres du PDF\" @onCloseButtonClick={{@onCloseButtonClicked}} @showModal={{@displayModal}}>\n  <:content>\n    <PixSelect\n      @value={{this.language}}\n      @onChange={{this.onChangeLanguage}}\n      @options={{this.options}}\n      @hideDefaultOption={{true}}\n      @label=\"Langue du référentiel (français ou anglais) :\"\n      class=\"pdf-modal--select\"\n    />\n  </:content>\n  <:footer>\n    <PixButton\n      @type=\"submit\"\n      @size=\"small\"\n      class=\"pix-button--background-transparent-light\"\n      {{on \"click\" @onCloseButtonClicked}}\n    >\n      Annuler\n    </PixButton>\n    <PixButton @size=\"small\" @triggerAction={{this.submit}}>Télécharger</PixButton>\n  </:footer>\n</PixModal>", {"contents":"<PixModal @title=\"Paramètres du PDF\" @onCloseButtonClick={{@onCloseButtonClicked}} @showModal={{@displayModal}}>\n  <:content>\n    <PixSelect\n      @value={{this.language}}\n      @onChange={{this.onChangeLanguage}}\n      @options={{this.options}}\n      @hideDefaultOption={{true}}\n      @label=\"Langue du référentiel (français ou anglais) :\"\n      class=\"pdf-modal--select\"\n    />\n  </:content>\n  <:footer>\n    <PixButton\n      @type=\"submit\"\n      @size=\"small\"\n      class=\"pix-button--background-transparent-light\"\n      {{on \"click\" @onCloseButtonClicked}}\n    >\n      Annuler\n    </PixButton>\n    <PixButton @size=\"small\" @triggerAction={{this.submit}}>Télécharger</PixButton>\n  </:footer>\n</PixModal>","moduleName":"pix-admin/components/target-profiles/pdf-parameters-modal.hbs","parseOptions":{"srcName":"pix-admin/components/target-profiles/pdf-parameters-modal.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class PdfParametersModal extends Component {
  @tracked language = null;
  @tracked errorMessage = null;
  constructor() {
    super(...arguments);
    this.options = [
      { value: 'fr', label: 'Français' },
      { value: 'en', label: 'Anglais' },
    ];
    this.language = 'fr';
  }

  @action
  submit() {
    if (this._isInvalid()) {
      this.errorMessage = 'Tous les champs doivent être renseignés';
      return;
    }

    this.errorMessage = null;
    this.args.onDownloadButtonClicked(this.language, this.args.name);
  }

  @action
  onChangeLanguage(language) {
    this.language = language;
  }

  _isInvalid() {
    return !this.language?.trim();
  }
}
