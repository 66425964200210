import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<section class=\"page-section\">\n  <div class=\"certification-center-information\">\n    {{#if this.isEditMode}}\n      <CertificationCenters::InformationEdit\n        @certificationCenter={{@certificationCenter}}\n        @availableHabilitations={{@availableHabilitations}}\n        @toggleEditMode={{this.toggleEditMode}}\n        @onSubmit={{@updateCertificationCenter}}\n      />\n    {{else}}\n      <CertificationCenters::InformationView\n        @certificationCenter={{@certificationCenter}}\n        @availableHabilitations={{@availableHabilitations}}\n        @toggleEditMode={{this.toggleEditMode}}\n      />\n    {{/if}}\n  </div>\n</section>", {"contents":"<section class=\"page-section\">\n  <div class=\"certification-center-information\">\n    {{#if this.isEditMode}}\n      <CertificationCenters::InformationEdit\n        @certificationCenter={{@certificationCenter}}\n        @availableHabilitations={{@availableHabilitations}}\n        @toggleEditMode={{this.toggleEditMode}}\n        @onSubmit={{@updateCertificationCenter}}\n      />\n    {{else}}\n      <CertificationCenters::InformationView\n        @certificationCenter={{@certificationCenter}}\n        @availableHabilitations={{@availableHabilitations}}\n        @toggleEditMode={{this.toggleEditMode}}\n      />\n    {{/if}}\n  </div>\n</section>","moduleName":"pix-admin/components/certification-centers/information.hbs","parseOptions":{"srcName":"pix-admin/components/certification-centers/information.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class Information extends Component {
  @tracked isEditMode = false;

  @action
  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }
}
