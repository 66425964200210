import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<table class=\"places__capacity\">\n  <tbody>\n    {{#each this.placesCapacityCategories as |placesCapacityCategory|}}\n      <tr>\n        <td>{{placesCapacityCategory.count}}</td>\n        <td><strong>{{placesCapacityCategory.label}}</strong></td>\n      </tr>\n    {{/each}}\n  </tbody>\n</table>", {"contents":"<table class=\"places__capacity\">\n  <tbody>\n    {{#each this.placesCapacityCategories as |placesCapacityCategory|}}\n      <tr>\n        <td>{{placesCapacityCategory.count}}</td>\n        <td><strong>{{placesCapacityCategory.label}}</strong></td>\n      </tr>\n    {{/each}}\n  </tbody>\n</table>","moduleName":"pix-admin/components/organizations/places/capacity.hbs","parseOptions":{"srcName":"pix-admin/components/organizations/places/capacity.hbs"}});
import Component from '@glimmer/component';

const categories = {
  FREE_RATE: 'Tarif gratuit',
  PUBLIC_RATE: 'Tarif public',
  REDUCE_RATE: 'Tarif réduit',
  SPECIAL_REDUCE_RATE: 'Tarif réduit spécial',
  FULL_RATE: 'Tarif plein',
};

export default class Capacity extends Component {
  get placesCapacityCategories() {
    return this.args.placesCapacity?.categories
      .filter(({ count }) => count > 0)
      .map(({ category, count }) => ({
        label: categories[category],
        count,
      }));
  }
}
