import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PixModal @title={{this.title}} @onCloseButtonClick={{@cancel}} @showModal={{@show}}>\n  <:content>\n    <p>{{@message}}</p>\n    <p class=\"confirm-popup__errors\">{{@error}}</p>\n  </:content>\n\n  <:footer>\n    <PixButton @backgroundColor=\"transparent-light\" @isBorderVisible={{true}} @triggerAction={{@cancel}}>\n      {{this.closeTitle}}\n    </PixButton>\n\n    <PixButton @triggerAction={{@confirm}} @loadingColor=\"white\" @backgroundColor=\"blue\">\n      {{this.submitTitle}}\n    </PixButton>\n  </:footer>\n</PixModal>", {"contents":"<PixModal @title={{this.title}} @onCloseButtonClick={{@cancel}} @showModal={{@show}}>\n  <:content>\n    <p>{{@message}}</p>\n    <p class=\"confirm-popup__errors\">{{@error}}</p>\n  </:content>\n\n  <:footer>\n    <PixButton @backgroundColor=\"transparent-light\" @isBorderVisible={{true}} @triggerAction={{@cancel}}>\n      {{this.closeTitle}}\n    </PixButton>\n\n    <PixButton @triggerAction={{@confirm}} @loadingColor=\"white\" @backgroundColor=\"blue\">\n      {{this.submitTitle}}\n    </PixButton>\n  </:footer>\n</PixModal>","moduleName":"pix-admin/components/confirm-popup.hbs","parseOptions":{"srcName":"pix-admin/components/confirm-popup.hbs"}});
import Component from '@glimmer/component';

export default class ConfirmPopup extends Component {
  get title() {
    return this.args.title || 'Merci de confirmer';
  }

  get closeTitle() {
    return this.args.closeTitle || 'Annuler';
  }

  get submitTitle() {
    return this.args.submitTitle || 'Confirmer';
  }

  get size() {
    return this.args.size || null;
  }
}
