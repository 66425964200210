import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"certification-info-field\">\n  {{#if @edition}}\n    <label for={{@fieldId}} class=\"certification-info-field__label\">\n      {{@label}}\n    </label>\n    {{#if @isTextarea}}\n      <Textarea id={{@fieldId}} @value={{@value}} class=\"form-control\" ...attributes />\n    {{else}}\n      <Input id={{@fieldId}} @type=\"text\" @value={{@value}} class=\"form-control\" ...attributes />\n    {{/if}}\n    {{#if @suffix}}\n      <span class=\"certification-info-field__suffix\">{{@suffix}}</span>\n    {{/if}}\n  {{else}}\n    <p>{{@label}}</p>\n    {{#if @linkRoute}}\n      <LinkTo @route={{@linkRoute}} @model={{@value}}>\n        {{this.valueWithSuffix}}\n      </LinkTo>\n    {{else}}\n      <p class={{@class}}>\n        {{if this.valueWithSuffix this.valueWithSuffix \" - \"}}\n      </p>\n    {{/if}}\n  {{/if}}\n</div>", {"contents":"<div class=\"certification-info-field\">\n  {{#if @edition}}\n    <label for={{@fieldId}} class=\"certification-info-field__label\">\n      {{@label}}\n    </label>\n    {{#if @isTextarea}}\n      <Textarea id={{@fieldId}} @value={{@value}} class=\"form-control\" ...attributes />\n    {{else}}\n      <Input id={{@fieldId}} @type=\"text\" @value={{@value}} class=\"form-control\" ...attributes />\n    {{/if}}\n    {{#if @suffix}}\n      <span class=\"certification-info-field__suffix\">{{@suffix}}</span>\n    {{/if}}\n  {{else}}\n    <p>{{@label}}</p>\n    {{#if @linkRoute}}\n      <LinkTo @route={{@linkRoute}} @model={{@value}}>\n        {{this.valueWithSuffix}}\n      </LinkTo>\n    {{else}}\n      <p class={{@class}}>\n        {{if this.valueWithSuffix this.valueWithSuffix \" - \"}}\n      </p>\n    {{/if}}\n  {{/if}}\n</div>","moduleName":"pix-admin/components/certifications/info-field.hbs","parseOptions":{"srcName":"pix-admin/components/certifications/info-field.hbs"}});
import Component from '@glimmer/component';

export default class CertificationInfoField extends Component {
  get valueWithSuffix() {
    if (this.args.suffix) {
      return `${this.args.value} ${this.args.suffix}`;
    }
    return this.args.value;
  }
}
