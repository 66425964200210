import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PixModal @title=\"{{this.actionName}}\" @onCloseButtonClick={{@toggleResolveModal}} @showModal={{@displayModal}}>\n  <:content>\n    <PixTextarea\n      @id=\"resolve-reason\"\n      @label=\"Résolution\"\n      @value={{@issueReport.resolution}}\n      type=\"text\"\n      maxLength=\"255\"\n      @errorMessage={{this.errorMessage}}\n      {{on \"change\" this.onChangeLabel}}\n      {{focus}}\n    />\n  </:content>\n\n  <:footer>\n    <PixButton\n      @type=\"submit\"\n      @size=\"small\"\n      class=\"pix-button--background-transparent-light\"\n      {{on \"click\" @toggleResolveModal}}\n    >\n      Annuler\n    </PixButton>\n    <PixButton @size=\"small\" @triggerAction={{this.resolve}}>{{this.actionName}}</PixButton>\n  </:footer>\n</PixModal>", {"contents":"<PixModal @title=\"{{this.actionName}}\" @onCloseButtonClick={{@toggleResolveModal}} @showModal={{@displayModal}}>\n  <:content>\n    <PixTextarea\n      @id=\"resolve-reason\"\n      @label=\"Résolution\"\n      @value={{@issueReport.resolution}}\n      type=\"text\"\n      maxLength=\"255\"\n      @errorMessage={{this.errorMessage}}\n      {{on \"change\" this.onChangeLabel}}\n      {{focus}}\n    />\n  </:content>\n\n  <:footer>\n    <PixButton\n      @type=\"submit\"\n      @size=\"small\"\n      class=\"pix-button--background-transparent-light\"\n      {{on \"click\" @toggleResolveModal}}\n    >\n      Annuler\n    </PixButton>\n    <PixButton @size=\"small\" @triggerAction={{this.resolve}}>{{this.actionName}}</PixButton>\n  </:footer>\n</PixModal>","moduleName":"pix-admin/components/certifications/issue-reports/resolve-issue-report-modal.hbs","parseOptions":{"srcName":"pix-admin/components/certifications/issue-reports/resolve-issue-report-modal.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CertificationIssueReportModal extends Component {
  @tracked label = null;
  @tracked errorMessage = null;

  @action
  resolve() {
    if (this._isInvalid()) {
      this.errorMessage = 'Le motif de résolution doit être renseigné.';
      return;
    }

    this.errorMessage = null;
    this.args.resolveIssueReport(this.args.issueReport, this.label);
    this.args.closeResolveModal();
  }

  @action
  onChangeLabel(event) {
    this.label = event.target.value;
  }

  get actionName() {
    return this.args.issueReport.isResolved ? 'Modifier la résolution' : 'Résoudre ce signalement';
  }

  _isInvalid() {
    return this.args.issueReport.isResolved && !this.label?.trim();
  }
}
