import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"page-header\">\n  <div class=\"page-title\">\n    <p>\n      <LinkTo @route=\"authenticated.target-profiles.target-profile.insights\">{{@targetProfile.name}}</LinkTo>\n      <span class=\"wire\">&nbsp;>&nbsp;</span>\n      Palier\n      {{@stage.id}}\n    </p>\n  </div>\n</header>\n\n<main class=\"page-body\">\n  {{#if @isEditMode}}\n    <Stages::UpdateStage\n      @stage={{@stage}}\n      @isTypeLevel={{this.isTypeLevel}}\n      @stageTypeName={{this.stageTypeName}}\n      @toggleEditMode={{@toggleEditMode}}\n      @maxLevel={{@targetProfile.maxLevel}}\n    />\n  {{else}}\n    <Stages::ViewStage\n      @stage={{@stage}}\n      @isTypeLevel={{this.isTypeLevel}}\n      @stageTypeName={{this.stageTypeName}}\n      @toggleEditMode={{@toggleEditMode}}\n    />\n  {{/if}}\n</main>", {"contents":"<header class=\"page-header\">\n  <div class=\"page-title\">\n    <p>\n      <LinkTo @route=\"authenticated.target-profiles.target-profile.insights\">{{@targetProfile.name}}</LinkTo>\n      <span class=\"wire\">&nbsp;>&nbsp;</span>\n      Palier\n      {{@stage.id}}\n    </p>\n  </div>\n</header>\n\n<main class=\"page-body\">\n  {{#if @isEditMode}}\n    <Stages::UpdateStage\n      @stage={{@stage}}\n      @isTypeLevel={{this.isTypeLevel}}\n      @stageTypeName={{this.stageTypeName}}\n      @toggleEditMode={{@toggleEditMode}}\n      @maxLevel={{@targetProfile.maxLevel}}\n    />\n  {{else}}\n    <Stages::ViewStage\n      @stage={{@stage}}\n      @isTypeLevel={{this.isTypeLevel}}\n      @stageTypeName={{this.stageTypeName}}\n      @toggleEditMode={{@toggleEditMode}}\n    />\n  {{/if}}\n</main>","moduleName":"pix-admin/components/stages/stage.hbs","parseOptions":{"srcName":"pix-admin/components/stages/stage.hbs"}});
import Component from '@glimmer/component';

const LEVEL = 'Niveau';
const THRESHOLD = 'Seuil';

export default class Stage extends Component {
  get isTypeLevel() {
    return this.args.stage.isTypeLevel;
  }

  get stageTypeName() {
    return this.isTypeLevel ? LEVEL : THRESHOLD;
  }
}
