import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PixTag @compact=\"true\" @color=\"blue-light\">\n  {{this.category}}\n</PixTag>", {"contents":"<PixTag @compact=\"true\" @color=\"blue-light\">\n  {{this.category}}\n</PixTag>","moduleName":"pix-admin/components/target-profiles/category.hbs","parseOptions":{"srcName":"pix-admin/components/target-profiles/category.hbs"}});
import Component from '@glimmer/component';
import { categories } from '../../models/target-profile';

export default class Category extends Component {
  get category() {
    const { category } = this.args;
    return categories[category];
  }
}
