import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FaIcon @icon={{this.icon}} class=\"tick-or-cross {{this.class}}\" />", {"contents":"<FaIcon @icon={{this.icon}} class=\"tick-or-cross {{this.class}}\" />","moduleName":"pix-admin/components/common/tick-or-cross.hbs","parseOptions":{"srcName":"pix-admin/components/common/tick-or-cross.hbs"}});
import Component from '@glimmer/component';

export default class TickOrCross extends Component {
  get icon() {
    return this.args.isTrue ? 'check' : 'times';
  }

  get class() {
    return this.args.isTrue ? 'tick-or-cross--valid' : 'tick-or-cross--invalid';
  }
}
