import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ConfirmPopup\n  @message={{this.message}}\n  @title=\"Supprimer un lot de place\"\n  @submitTitle=\"Confirmer\"\n  @confirm={{this.deleteOrganizationPlaceLot}}\n  @cancel={{@toggle}}\n  @show={{@show}}\n/>", {"contents":"<ConfirmPopup\n  @message={{this.message}}\n  @title=\"Supprimer un lot de place\"\n  @submitTitle=\"Confirmer\"\n  @confirm={{this.deleteOrganizationPlaceLot}}\n  @cancel={{@toggle}}\n  @show={{@show}}\n/>","moduleName":"pix-admin/components/organizations/places/delete-modal.hbs","parseOptions":{"srcName":"pix-admin/components/organizations/places/delete-modal.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DeleteModal extends Component {
  @service notifications;

  get message() {
    if (!this.args.show) return '';
    return `Êtes-vous sûr de vouloir supprimer ce lot de place: ${this.args.organizationPlacesLot.reference} ?`;
  }

  @action
  async deleteOrganizationPlaceLot() {
    try {
      await this.args.organizationPlacesLot.deleteRecord();
      await this.args.organizationPlacesLot.save({ adapterOptions: { organizationId: this.args.organizationId } });

      this.notifications.success(`Le lot de place ${this.args.organizationPlacesLot.reference} a été supprimé.`);
      this.args.refreshModel();
    } catch (error) {
      this.notifications.error(`Le lot de place ${this.args.organizationPlacesLot.reference} n'a pas été supprimé.`);
    }

    this.args.toggle();
  }
}
