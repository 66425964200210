import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<input {{did-insert this.updateState}} {{did-update this.updateState @state}} type=\"checkbox\" ...attributes />", {"contents":"<input {{did-insert this.updateState}} {{did-update this.updateState @state}} type=\"checkbox\" ...attributes />","moduleName":"pix-admin/components/common/tubes-selection/checkbox.hbs","parseOptions":{"srcName":"pix-admin/components/common/tubes-selection/checkbox.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class TubesSelectionCheckbox extends Component {
  @action
  updateState(element) {
    if (this.args.state === 'checked') {
      element.checked = true;
      element.indeterminate = false;
    } else if (this.args.state === 'indeterminate') {
      element.checked = false;
      element.indeterminate = true;
    } else {
      element.checked = false;
      element.indeterminate = false;
    }
  }
}
