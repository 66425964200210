import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<section class=\"page-section\">\n  <header class=\"page-section__header\">\n    <h2 class=\"page-section__title\">Créer un nouveau tag</h2>\n  </header>\n  <form {{on \"submit\" this.createNewTag}} class=\"tools__create-tag-form\">\n    <PixInput @id=\"tagNameInput\" {{on \"change\" this.onChangeTagName}} @label=\"Nom du tag\" />\n    <PixButton @type=\"submit\" @size=\"small\">Créer le tag</PixButton>\n  </form>\n</section>", {"contents":"<section class=\"page-section\">\n  <header class=\"page-section__header\">\n    <h2 class=\"page-section__title\">Créer un nouveau tag</h2>\n  </header>\n  <form {{on \"submit\" this.createNewTag}} class=\"tools__create-tag-form\">\n    <PixInput @id=\"tagNameInput\" {{on \"change\" this.onChangeTagName}} @label=\"Nom du tag\" />\n    <PixButton @type=\"submit\" @size=\"small\">Créer le tag</PixButton>\n  </form>\n</section>","moduleName":"pix-admin/components/tools/new-tag.hbs","parseOptions":{"srcName":"pix-admin/components/tools/new-tag.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import get from 'lodash/get';

export default class NewTag extends Component {
  @service store;
  @service notifications;

  @action
  async createNewTag(event) {
    event.preventDefault();
    let tag;

    try {
      tag = this.store.createRecord('tag', { name: this.tagName });
      await tag.save();

      this.notifications.success('Le tag a bien été créé !');
      document.getElementById('tagNameInput').value = '';
    } catch (response) {
      this.store.deleteRecord(tag);
      const status = get(response, 'errors[0].status');
      if (status === '412') {
        this.notifications.error('Ce tag existe déjà.');
      } else {
        this.notifications.error('Une erreur est survenue. Veuillez réessayer.');
      }
    }
  }

  @action
  onChangeTagName(event) {
    this.tagName = event.target.value;
  }
}
